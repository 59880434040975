.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Game loader **/
#game-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(35, 31, 32);
  background: url("https://gamescenter.dokki.app/common/assets/bg-sparkles.png"), url("https://gamescenter.dokki.app/common/assets/bg-gradient.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 110%, cover;
}

#game-loader .game-loader__icon {
  display: block;
  max-width: 150px;
  min-width: 100px;
}

#game-loader .game-loader__progress {
  margin-top: 1em;
  width: 140px;
  overflow: hidden;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.2);
}

#game-loader .game-loader__progress .bar {
  background: white;
  border-radius: 2rem;
  height: 1em;
}
